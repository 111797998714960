import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BackGlossary from '../../../../components/BackGlossary';
import BannerRussel from '../../../../components/BannerRussel';

const GlossaryPageContent = () => {
  return (
    <div className="md:px-5 max-w-[1285px]">
      <div className="mb-8 text-xl font-bold text-left md:text-2xl">Permission Utilization Rate (PUR)</div>
      <p className="mb-12 text-lg text-left">
        PUR stands for Permission Utilization Rate. As the opposite of UUR, it is the percentage of granted permissions
        which are actually utilized by the principal (application or user).
      </p>

      <p className="mb-12 text-lg text-left">
        A high PUR may indicate that LPA has properly been assigned, but it may also indicate over entitlement – ie,
        that assigned permissions are being utilized, but the user shouldn’t actually be using them. A low PUR may
        indicate the permission policy contains permissions which aren’t being used (and opening up more attack
        surface).
      </p>

      <p className="mb-12 text-lg text-left">
        The ideal PUR depends on the specific application or user. However, a general rule of thumb is to keep the PUR
        as high as possible while still ensuring that the principal is not over-entitled per the use case.
      </p>

      <p className="mb-6 text-lg text-left">Here are some of the benefits of having a high PUR:</p>

      <ul className="mb-12 ml-12 text-lg list-disc">
        <li className="mb-5">Users have the access they need to do their job.</li>
        <li className="mb-5">
          Unnecessary permissions which would never be used by a legit user cannot otherwise be abused by an attacker.
        </li>
        <li className="mb-5">
          Reduces compliance risks: A lower PUR can help organizations to meet compliance requirements, such as those
          imposed by the General Data Protection Regulation (GDPR) and SOC2.
        </li>
      </ul>

      <p className="mb-12 text-lg text-left">
        To help organizations improve their PUR, Trustle detects all users across each integrated cloud system, and
        helps you to understand which permissions are being used (or which are not), helping keep your users on least
        privileged, highly utilized permissions when access is needed, while bringing users back to zero standing access
        once their tasks are completed.
      </p>

      <p className="mb-12 text-lg text-left">
        Automation like this helps to free up time for security and IT teams to focus on more important tasks, while
        substantially driving down the attack surface and ensuring all compliance and paper trail requirements are met.
      </p>
    </div>
  );
};

const PermissionUtilizationRate = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `PUR`;
  const description = `Permission Utilization Rate`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={`(${description})`} />
          </div>
        </section>

        <section className="pb-8">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <GlossaryPageContent />
            </div>
          </div>
        </section>
      </BackgroundImage>

      <section className="pb-16">
        <BannerRussel
          title="Wondering how Trustle works?"
          description={'Get an inside look at Trustle. Schedule a demo with us today.'}
        />
      </section>
    </Layout>
  );
};

export default PermissionUtilizationRate;
